
* {
    box-sizing: border-box;
}

.overflow-hidden {
    overflow: hidden;
}

.flex {
    display: flex;
}

.flex-justify-center {
    justify-content: center;
}

.flex-justify-between {
    justify-content: space-between;
}

.flex-align-center, .flex-center-all {
    align-content: center;
    align-items: center;
}

.flex-center {
    align-items: center;
    justify-content: center;
}

.flex-col {
    flex-direction: column;
}

.flex-auto {
    flex: 1 1 auto;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    display: flex;
    height: 100vh;
    background: #F2F4F7;
    font-family: "Roboto", sans-serif;
}

#root {
    width: 100%;
}

.ew-container-layout {
    display: flex;
    flex: 1;
    overflow: hidden; /* Prevent left content from overlapping the right sidebar */

    .hide-on-desktop {
        display: none !important;
    }

    &.open-sidebar {
        .left-layout {
            visibility: visible;
            transition: all .5s;
            width: calc(100% - 470px);
        }
        .sidebar-info-box {
            right: 80px;
            visibility: visible;
            transition: all .5s;
            transform: translateZ(0);
        }
        .ew-video-container {
            .presenters.screen_shared_fullscreen {
                .ew-n-presenters {
                    .presenter-column.screen-sharing-user-fullscreen {
                        width: calc(100% - 490px);
                    }
                }
            }
        } 
            
    }
    &.moderator-view {
        .polls-content-area {
            .polls-inner-container {
                height: calc(100vh - 85px);
            }
        }
    }
}

.live-event-main {
    height: 100%;
    display: flex;
}

.left-layout {
    // flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    // margin-right: 80px; /* Width of the fixed sidebar */
    width: calc(100% - 80px);
    height: 100%;
    transition: all .5s;
}

.layout-header, .layout-footer {
    padding: 0 15px;
}

.layout-header {
    transition-property: top !important;
    display: flex;
    flex: 0 0 70px;
    min-height: 70px;
    height: 70px;
    max-height: 70px;
    z-index: 91;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    box-shadow: 0px 4px 48px 0px #2B2B3014;
    span.ew-logo {
        margin-right: 20px;
        display: none;
        svg {
            width: 35px;
        }
    }
    .ew-event-details {
        // border-left: 1px solid rgba(234, 233, 233);
        // padding-left: 20px;
        .ew-event-title {
            margin: 0;
            color: #333333;
            font-size: 16px;
            font-weight: 500;
        }
        .not-live-label {
            border: 1px solid #66666666;
            border-radius: 4px;
            color: #666;
            font-size: 12px;
            padding: 2px 5px;
            margin-top: 4px;
            display: inline-flex;
            span.status-sign {
                width: 7px;
                height: 7px;
                border-radius: 10px;
                background: #666;
                display: inline-block;
                margin-right: 6px;
                padding: 0;
                display: inline-flex;
                justify-content: center;
            }
            span.event-time {
                display: none;
                margin-left: 5px;
                color: #666;
            }
            &.event-live {
                color: #178EFC;
                .status-sign {
                    background: #178EFC;
                }
                span.event-time {
                    display: flex;
                }
            }
        }
        .recording {
            border: 1px solid #F9DADE;
            border-radius: 4px;
            color: #333333;
            font-size: 12px;
            padding: 2px 5px;
            margin-top: 4px;
            display: inline-flex;
            margin-left: 5px;
            background: #F9DADE;
            cursor: pointer;
            span.status-sign {
                width: 7px;
                height: 7px;
                border-radius: 10px;
                background: #E44258;
                display: inline-block;
                margin-right: 6px;
                padding: 0;
                display: inline-flex;
                justify-content: center;
            }
            &.paused {
                border-color: #666666;
                background: none;
                span.status-sign {
                    background: #666666;
                }
            }
        }
    }

    .event-option-menu {
        ul {
            list-style: none;
            margin: 0 0 0 18px;
            padding: 0;
            gap: 2px;
            position: relative;
            &.quick-actions {
                &:before {
                    content: "";
                    position: absolute;
                    left: -10px;
                    width: 1px;
                    height: 35px;
                    background: #D6D6D6;
                }
                li {
                    a {
                        color: #333;
                    }
                }
            }
            li {
                transition: all .3s ease-in-out;
                a {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    color: #666666;
                    text-decoration: none;
                    font-size: 14px;
                    width: 100%;
                    height: 100%;
                    gap: 4px;
                    padding: 5px 10px;
                    transition: .3s all;
                    span.menu-icon {
                        height: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg {
                            width: auto;
                            height: auto;
                        }
                    }
                    &:hover {
                        background: #DFEBFF;
                        border-radius: 4px;
                        color: #1272CA;
                        transition: .3s all;
                        span.menu-icon {
                            svg {
                                path, rect {
                                    fill: #1272CA;
                                    &.camera-line {
                                        fill: #fff;
                                    }
                                }
                                path.line {
                                    stroke: #1272CA;
                                }
                            }
                        }
                    }
                }
                button.only-icon {
                    padding: 0 3px;
                    min-width: auto;
                    border: none;
                    background: none;
                    border-radius: 4px;
                    height: 56px;
                    &:hover {
                        background: #E6E9EF;
                        svg {
                            path {
                                path {
                                    fill: #178EFC;
                                }
                            }
                        }
                    }
                }
                &.dropdown-menu {
                    padding: 5px 10px;
                    position: relative;
                }
                &.stop-share {
                    a {
                        border-radius: 4px;
                        background: #F9DADE;
                        span {
                            color: #E44258;
                            &.menu-icon {
                                svg {
                                    path {
                                        fill: #E44258;
                                    }
                                }
                            }
                        }
                    }
                } 
            }
        }
    }
    .ew-event-actions {
        position: relative;
        margin-left: 8px;
        gap: 8px;
        button {
            border-radius: 4px;
            height: 38px;
            min-width: 38px;
            line-height: 38px;
            padding: 10px 30px;
            color: #fff;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            &.go-live {
                background: #178EFC;
                font-size: 14px;
                font-weight: 600;
                // margin: 0 8px;
                &:hover {
                    background: #1272CA;
                }
            }
            &.exit-btn {
                min-width: 38px;
                padding: 0 15px;
                &.exit-dropdown {
                    padding: 0 7px;
                    border-left: 1px solid #FF8E9E;
                }
            }
        }
        .exit-actions-btn {
            position: relative;
            overflow: hidden;
            border-radius: 4px;
            button {
                border-radius: 0;
                &.leave-button {
                    border-right: 1px solid #FF8E9E;
                }
            }
        }
    }
}

.layout-footer {
    // bottom: 0;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    height: auto;
    justify-content: center;
    .ew-video-options {
        ul {
            position: relative;
            margin: 0;
            padding: 0;
            li {
                flex-direction: column;
                cursor: pointer;
                max-width: 105px;
                padding: 10px;
                min-width: 80px;
                .controls-icon {
                    width: 48px;
                    height: 48px;
                    background: #fff;
                    border-radius: 50%;
                    margin-bottom: 10px;
                    box-shadow: 0px 4px 12.5px 0.5px #0000001A;
                    transition: all .25s ease-in;
                }
                span {
                    color: #666666;
                    font-size: 14px;
                }
                &.mic-icon, &.layout-icon {
                    &:hover {
                        .controls-icon {
                            svg {
                                path {
                                    stroke: #fff;
                                    fill: none;
                                }
                            }
                        }
                    }
                    
                    &.mic-on {
                        .controls-icon {
                            svg {
                                &.active {
                                    display: flex;
                                }
                                &.disabled {
                                    display: none;
                                }
                            }
                        }
                    }
                    &.mic-off {
                        .controls-icon {
                            svg {
                                &.active {
                                    display: none;
                                }
                                &.disabled {
                                    display: flex;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    .controls-icon {
                        box-shadow: none;
                        background: #178EFC;
                        svg {
                            path {
                                fill: #fff;
                                // stroke: #fff;
                            }
                        }
                    }
                }
                &.layout-icon {
                    &:hover {
                        .layout-settings {
                            display: block;
                        }
                    }
                }
                &.camera-icon {
                    &:hover {
                        .camera-settings {
                            display: block;
                        }
                    }
                    &.cam-on {
                        .controls-icon {
                            svg {
                                &.active {
                                    display: flex;
                                }
                                &.disabled {
                                    display: none;
                                }
                            }
                        }
                    }
                    &.cam-off {
                        .controls-icon {
                            svg {
                                &.active {
                                    display: none;
                                }
                                &.disabled {
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ew-video-controls-box {
    position: absolute;
    width: 335px;
    top: 70px;
    border-radius: 8px;
    background: #fefefe;
    padding: 18px;
    right: 0;
    z-index: 9;
    max-height: 80vh;
    overflow-y: auto;
    transition: opacity 5s ease-in-out;
    box-shadow: 0px 4px 48px 0px #2B2B3014;
    display: none;
    &.open-dropdown {
        display: block;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        gap: 20px;
        li {
            width: 105px;
            border: 1px solid #D9D9D9;
            background: #FEFEFE;
            border-radius: 4px;
            cursor: pointer;
            .ew-layout-icons {
                width: 35px;
                height: 30px;
                text-align: center;
            }
            span {
                font-size: 14px;
                font-weight: 400;
                color: #707070;
            }
            &:hover {
                border-color: #178EFC !important;
                color: #178EFC;
                .ew-layout-icons {
                    svg {
                        path {
                            fill: #178EFC;
                        }
                        rect {
                            stroke: #178EFC;
                        }
                    }
                }
                span {
                    color: #178EFC;
                }
            }
            &.spotlight-me {
                &:hover {
                    .ew-layout-icons {
                        svg {
                            path {
                                stroke: #178EFC;
                                fill: none;
                            }
                        }
                    }
                }
            }
        }
    }
    button.btn {
        width: 100%;
        border: none;
        border-radius: 10px;
        margin-top: 12px;
        height: 40px;
        font-size: 16px;
        gap: 8px;
        background: #178EFC;
        color: #fff;
        cursor: pointer;
        &.camera-settings-btn {
            background: #DFEBFF;
            color: #333333;
        }
        &.noise-reduction {
            background: #DFEBFF;
            color: #333333;
        }
    }
}

.camera-settings {
    .camera-img-box {
        img {
            width: 100%;
        }
    }
    .ew-camera-bg-selection {
        .video-bg-listing {
            margin-top: 20px;
            h4 {
                font-size: 14px;
                color: #999999;
                margin: 0 0 10px;
                font-weight: 400;
            }
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                max-height: 230px;
                overflow-y: auto;
                max-height: 120px;
                li {
                    width: 88px;
                    height: 51px;
                    border-radius: 4px;
                    background: #F4F6F8;
                    border: 1px solid #D9D9D9;
                    list-style: none;
                    cursor: pointer;
                    padding: 0;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                    &.bg-default {
                        span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            height: 100%;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                            color: #C5C5C5;
                            gap: 4px;
                            svg {
                                min-height: 19px;
                                path {
                                    fill: #C5C5C5;
                                }
                            }
                        }
                        &:hover {
                            span {
                                color: #178EFC;
                                svg {
                                    path {
                                        fill: #178EFC;
                                    }
                                }
                            }
                        }
                        &.active {
                            border: 1px solid #178EFC;
                            span {
                                color: #178EFC;
                                svg {
                                    path {
                                        fill: #178EFC;
                                    }
                                }
                            }
                        }
                    }
                    &.active {
                        border: 1px solid #178EFC;
                    }
                }
            }
        }
    }
}

.dropdown {
    position: absolute;
    width: 175px;
    top: 55px;
    border-radius: 8px;
    background: #fefefe;
    padding: 0;
    right: 0;
    z-index: 9;
    max-height: 80vh;
    overflow-y: auto;
    transition: opacity 5s ease-in-out;
    box-shadow: 0px 4px 48px 0px #2B2B3014;
    display: none;
    &.open-dropdown {
        display: block;
    }
    ul {
        li {
            border-bottom: 1px solid #E8EAF0;
            &:last-child {
                border: none;
            }
            a {
                color: #2B2B30;
                text-decoration: none;
                padding: 9px 15px;
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 14px;
                img {
                    margin-right: 5px;
                }
                &:hover {
                    background: #F9DADE;
                }
            }
        }
    }
}


.video-container {
    // flex: 1;
    /* Add your styles for the video streaming area */
    // margin-top: 40px; /* Adjust based on your header height */
    // margin-bottom: 40px; /* Adjust based on your footer height */

    flex: 1 1 auto;
    height: 100%;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
}

.right-layout {
    width: 80px;
    background-color: #FFFFFF;
    position: fixed;
    top: 70px;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #E8E8EA;
    z-index: 9;
}

.sidebar {
    width: 100%;
    height: 100%;
    padding-top: 50px;
    z-index: 3;
    overflow-y: auto;
    background: #fff;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        flex-direction: column;
        li {
            transition: all .25s ease-in;
            width: 55px;
            height: 55px;
            border-radius: 16px;
            margin-bottom: 15px;
            position: relative;
            a {
                flex-direction: column;
                text-decoration: none;
                color: #666666;
                font-size: 12px;
                font-weight: 500;
                gap: 3px;
                span {
                    text-align: center;
                    &.count {
                        position: absolute;
                        top: 0;
                        right: 0;
                        border-radius: 50%;
                        background: #178EFC;
                        color: #fff;
                        padding: 2px;
                        font-size: 10px;
                        min-width: 17px;
                        min-height: 17px;
                    }
                }
                .ew-action-icon {
                    img {
                        &.default {
                            display: block;
                        }
                        &.active {
                            display: none;
                        }
                    }
                }
            }
            &:hover, &.active {
                background: #DFEBFF;
                a {
                    color: #178EFC;
                    .ew-action-icon {
                        img {
                            &.default {
                                display: none;
                            }
                            &.active {
                                display: block;
                            }
                        }
                    }
                }
            }
            &.new-notification {
                &:after {
                    display: block;
                    content: "";
                    position: absolute;
                    right: 6px;
                    top: 6px;
                    background: #1272CA;
                    width: 8px;
                    height: 8px;
                    border-radius: 15px;
                }
            }
        }
        &.leave-event {
            position: absolute;
            bottom: 0;
            width: 100%;
            background: #fff;
            li {
                a {
                    span {
                        color: #E44258;
                    }
                }
                &:hover {
                    a {
                        span {
                            color: #E44258;
                        }
                    }
                }
            }
        }
    }
}

.ew-video-container {
    padding: 10px;
    margin-top: 70px;
    height: calc(100vh - 70px);
    
    .presenters {
        // background: #1B1A1D;
        height: 100%;
        // border-radius: 15px;
        overflow: hidden;
        position: relative;
        .ew-n-presenters {
            height: 100%;
            position: relative;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow-x: auto;
            flex-wrap: wrap;
            overflow-y: hidden;
            gap: 10px;
            .presenter-column {
                height: 100%;
                // background: #1B1A1D;
                position: relative;
                // overflow: hidden;
                width: 100%;
                float: none;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                // padding: 5px !important;
                .ew-video-wrapper {
                    position: relative;
                    height: 100%;
                    width: 100%;
                    border-radius: 4px;
                    // overflow: hidden;
                    display: flex;
                    align-items: center;
                    background: #1B1A1D;
                    .refresh-screen {
                      right: 7px;
                    }
                    .ew-presenter-profile {
                        flex-direction: column;
                        height: 100%;
                        width: 100%;
                        .img-wrapper {
                            width: 100px;
                            height: 100px;
                            background: #fff;
                            border-radius: 50%;
                            font-size: 40px;
                            background-size: cover;
                        }
                        .ew-presenter-title {
                            font-size: 18px;
                            font-weight: 500;
                            color: #fff;
                            margin-top: 8px;
                        }
                    }
                    .ew-live-video {
                        // padding-bottom: 0;
                        // padding-top: 56.25%;
                        // height: 0;
                        // position: relative;
                        width: 100%;
                        max-height: 100%;
                        // position: relative;
                        video {
                            width: 100% !important;
                            top: 0px;
                            left: 0px;
                            position: absolute !important;
                            height: 100% !important;
                            -webkit-object-fit: cover;
                            -moz-object-fit: cover;
                            -ms-object-fit: cover;
                            -o-object-fit: cover;
                            object-fit: cover;
                            border-radius: 0;
                        }
                    }
                    .presenter-name {
                        position: absolute;
                        z-index: 1;
                        left: 10px;
                        bottom: 10px;
                        background: rgba(0, 0, 0, .3);
                        color: #fff;
                        font-size: 14px;
                        font-weight: 500;
                        padding: 10px 20px;
                        height: 38px;
                        border-radius: 30px;
                    }
                    .stop-screen-sharing {
                        flex-direction: column;
                        width: 100%;
                        .ew-presenter-title {
                            flex-direction: column;
                            color: #fff;
                            .name {
                                margin: 20px 0 25px;
                            }
                            button {
                                background: none;
                                color: #fff;
                                svg {
                                    margin-right: 7px;
                                }
                                &:hover {
                                    background: #178EFC;
                                    box-shadow: 0px 4px 12px 0px #178EFB99;
                                }
                            }
                        }
                    }
                    .video-aspect-ratio {
                        position: absolute;
                        right: 10px;
                        bottom: 10px;
                        z-index: 9;
                        span {
                            border-radius: 50%;
                            background: #0000004D;
                            width: 36px;
                            height: 36px;
                            display: flex;
                            cursor: pointer;
                        }
                    }
                }
                &.cam-off {
                    background: #1B1A1D;
                    .ew-live-video {
                        transform: translate(-50%);
                        position: absolute;
                        width: 0;
                        height: 0;
                        visibility: hidden;
                    }
                }
                &.go-live-message {
                    .ew-presenter-profile {
                        .ew-go-live-info {
                            flex-direction: column;
                            color: #fff;
                            p {
                                font-size: 18px;
                                font-weight: 500;
                                span {
                                    font-size: 24px;
                                    font-weight: 600;
                                }
                            }
                            span {
                                font-size: 16px;
                                font-weight: 400;
                                color: #E8E8EA;
                            }
                        }
                    }
                }

                &.fill-content {
                    .video-aspect-ratio {
                        span.fill-screen {
                            display: flex;
                        }
                        span.fit-screen {
                            display: none;
                        }
                    }
                }
                &.fit-content { 
                    .video-aspect-ratio {
                        span.fill-screen {
                            display: none;
                        }
                        span.fit-screen {
                            display: flex;
                        }
                    }
                    .ew-live-video {
                        video {
                            -webkit-object-fit: contain;
                            -moz-object-fit: contain;
                            -ms-object-fit: contain;
                            -o-object-fit: contain;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
        &.active-presenter-1 {
            .presenter-column {
                width: 100%;
                height: 100%;
            }
        }
        &.active-presenter-2 {
            .presenter-column {
                width: calc(50% - 8px);
                height: 100%;
            }
        }
        &.active-presenter-3, &.active-presenter-4 {
            .presenter-column {
                width: calc(50% - 8px);
                height: 49%;
            }
        }
        &.active-presenter-5, &.active-presenter-6 {
            .presenter-column {
                width: calc(33.3% - 8px);
                height: 49%;
            }
        }
        &.active-presenter-7, &.active-presenter-8 {
            .presenter-column {
                width: 25%;
                height: 50%;
            }
        }
        &.active-presenter-9, &.active-presenter-10, &.active-presenter-11, &.active-presenter-12 {
            .presenter-column {
                width: 25%;
                height: 33.33%;
            }
        }
        &.screen_shared_fullscreen {
            display: flex;
            justify-content: center;
            .ew-n-presenters {
                flex-wrap: nowrap;
                webkit-justify-content: initial;
                -ms-flex-pack: initial;
                justify-content: initial;
                align-items: flex-end;
                gap: 5px;
                .presenter-column {
                    flex-direction: row;
                    left: inherit;
                    transform: inherit;
                    -webkit-transform: inherit;
                    -moz-transform: inherit;
                    top: 0;
                    width: 178px;
                    height: 100px;
                    min-width: 178px;
                    .presenter-name {
                        padding: 5px 12px;
                        height: 28px;
                        font-size: 12px;
                        left: 5px;
                        bottom: 5px;
                    }
                    .presenter-options-dropdown {
                        right: 5px;
                        bottom: 5px;
                        span {
                            height: 28px;
                            width: 28px;
                        }
                        ul {
                            li {
                                span {
                                    height: auto;
                                    width: 100%;
                                }
                            }
                        }
                    }
                    &.screen-sharing-user-fullscreen {
                        width: calc(100% - 100px);
                        height: calc(100% - 200px);
                        left: 10px;
                        padding: 0 !important;
                        z-index: 1;
                        position: fixed;
                        top: 80px;
                        right: 0;
                        display: block;
                        margin-top: 0;
                        transition: all 0.5s;
                        -webkit-transition: all 0.5s;
                        -moz-transition: all 0.5s;
                        .presenter-name {
                            bottom: 10px;
                            left: 10px;
                            font-size: 14px;
                            height: 38px;
                            padding: 10px 20px;
                        }
                        .presenter-options-dropdown {
                            right: 10px;
                            bottom: 10px;
                            span {
                                height: 36px;
                                width: 36px;
                            }
                        }
                        .ew-video-wrapper {
                            .ew-live-video {
                                video {
                                    -webkit-object-fit: contain;
                                    -moz-object-fit: contain;
                                    -ms-object-fit: contain;
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                }
                &::-webkit-scrollbar {
                     /* Width of the scrollbar */
                    height: 4px;
                }
                &::-webkit-scrollbar-track {
                    background: #E8E8EA; /* Color of the track */
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    background: #178EFC; /* Color of the scrollbar handle */
                    border-radius: 10px; /* Rounded corners for the scrollbar handle */
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #178EFC; /* Color of the scrollbar handle on hover */
                }
            }
            .presenter-column {
                .ew-video-wrapper {
                    .ew-presenter-profile {
                        justify-content: flex-start;
                        margin-top: 18px;
                        .img-wrapper {
                            width: 38px;
                            height: 38px;
                            font-size: 18px;
                        }
                        .presenter-name {
                            padding: 4px 10px;
                            height: 26px;
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }
}

.attendee-layout {
    .ew-video-container {
        height: calc(100vh - 70px);
        .presenters.screen_shared_fullscreen {
            .ew-n-presenters {
                .presenter-column.screen-sharing-user-fullscreen {
                    height: calc(100% - 202px);
                }
            }
        }
    }
    .layout-header {
        width: calc(100% - 80px);
        transition: width .5s;
    }
    &.open-sidebar {
        .layout-header {
            width: calc(100% - 470px);
        }
    }
    .right-layout {
        top: 0;
        .sidebar-info-box {
            top: 0;
            height: 100%;
            .sidebar-header {
                h3 {
                    margin: 0;
                }
            }
        }
    }
    .polls-content-area {
        .polls-inner-container {
            height: 100vh;
        }
    }
}

// messgaes css
.camera-permission-msg {
    max-width: 400px;
    margin: 0 auto;
    color: #fff;
    text-align: center;
    flex-direction: column;
    .img-wrapper {
        margin-bottom: 10px;
    }
    .message-text {
        flex-direction: column;
        p {
            line-height: 24px;
            font-size: 14px;
            span {
                font-weight: 600;
            }
        }
        button {
            margin-top: 5px;
            &.retry-btn {
                border: 1px solid #E8E8EA99;
                background: none;
                color: #fff;
            }
        }
    }
}

/* Track */
.video-bg-listing ul::-webkit-scrollbar,
.ew-video-controls-box::-webkit-scrollbar,
.chat-thread::-webkit-scrollbar,
.private-chat-user-list ul::-webkit-scrollbar,
.thin-scrollbar::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
}

.video-bg-listing ul::-webkit-scrollbar-track,
.ew-video-controls-box::-webkit-scrollbar-track,
.chat-thread::-webkit-scrollbar-track,
.private-chat-user-list ul::-webkit-scrollbar-track,
.thin-scrollbar::-webkit-scrollbar-track {
    background: #E8E8EA; /* Color of the track */
}

/* Handle */
.video-bg-listing ul::-webkit-scrollbar-thumb,
.ew-video-controls-box::-webkit-scrollbar-thumb,
.chat-thread::-webkit-scrollbar-thumb,
.private-chat-user-list ul::-webkit-scrollbar-thumb,
.thin-scrollbar::-webkit-scrollbar-thumb {
    background: #178EFC; /* Color of the scrollbar handle */
    border-radius: 6px; /* Rounded corners for the scrollbar handle */
}

/* Handle on hover */
.video-bg-listing ul::-webkit-scrollbar-thumb:hover,
.ew-video-controls-box::-webkit-scrollbar-thumb:hover,
.chat-thread::-webkit-scrollbar-thumb:hover,
.private-chat-user-list ul::-webkit-scrollbar-thumb:hover,
.thin-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #178EFC; /* Color of the scrollbar handle on hover */
}

/* Styling for the toggle button */
.toggle-container {
    display: flex;
    align-items: center;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    transition: .4s;
}

.toggle-slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: .4s;
}

input:checked + .toggle-slider {
    background-color: #1F8AF4;
}

input:checked + .toggle-slider:before {
    transform: translateX(14px);
}

.sidebar-info-box {
    position: fixed;
    right: 0;
    top: 70px;
    z-index: 2;
    background: #fff;
    height: calc(100% - 70px);
    width: 390px;
    transition: transform .5s;
    transform: translate3d(100%, 0, 0);
}

// chat messages area scss
    .chat-thread {
    padding: 15px;
    height: calc(100vh - 190px);
    overflow-y: auto;
    overflow-x: hidden;
}
.attendee-layout {
    .chat-thread {
        height: calc(100vh - 61px);
    }
}
.chat-message {
    padding-bottom: 12px;
    position: relative;
    gap: 8px;
    .message-box {
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        min-width: 100px;
        .username {
            width: auto;
            margin-right: 5px;
            margin-bottom: 3px;
            max-width: calc(100% - 50px);
            width: calc(100% - 50px);
            word-wrap: break-word;
            overflow: hidden;
            font-size: 14px;
            color: #333;
            span.msg-time {
                font-size: 11px;
                color: #808080;
                font-weight: 500;
                margin: 0;
                text-transform: capitalize;
            }
        }
        .message-text {
            background: #f2f2f2;
            font-size: 14px;
            color: #ececec;
            padding: 10px 20px;
            border-radius: 8px;
            position: relative;
            margin-bottom: 8px;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
            float: right;
            word-break: break-word;
            max-width: 270px;
            p {
                margin: 0;
                display: inline;
                line-height: 1.6em;
                color: #666;
                span.mention-tag {
                    color: #178EFC;
                    font-weight: 500;
                }
            }
            .chat-action {
                position: absolute;
                right: -45px;
                top: 12px;
                opacity: 0;
                transition: all .3s;
                a {
                    margin: 0 1px;
                    img {
                        &.default {
                            display: inline-block;
                        }
                        &.hovered {
                            display: none;
                        }
                    }
                    &:hover {
                        img {
                           &.default {
                                display: none;
                           }
                           &.hovered {
                            display: inline-block;
                           } 

                        }
                    }
                    // &.reply-chat, &.delete-chat {
                    //     &:hover {
                    //         svg {
                    //             path {
                    //                 fill: #666666;
                    //                 color: #666;
                    //             }
                    //         }
                    //     }
                    // }
                }
            }
            &:hover {
                .chat-action {
                    opacity: 1;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &.sent {
        justify-content: flex-end;
        text-align: right;
        .message-box {
            .message-text {
                border-radius: 8px 8px 0;
                text-align: left;
                .chat-action {
                    right: inherit;
                    left: -45px;
                }
            }
        }
    }
    &.received {
        .user {
            width: 42px;
            min-width: 42px;
            span.name-tag {
                background: #cbd6da;
                height: 40px;
                width: 40px;
                border-radius: 100%;
                text-align: center;
                line-height: 35px;
                font-size: 16px;
                font-weight: 400;
                color: #333333;
            }
        }
        .message-box {
            align-items: flex-start;
            .username {
                .msg-time {
                    margin-left: 8px;
                }
                .position {
                    color: #333333;
                    font-size: 12px;
                    margin-left: 3px;
                    font-weight: 500;
                }
            }
        }
    }
    &.co-host-msg {
        .message-box {
            .username {
                .position {
                    color: #178EFC;
                }
            }
            .message-text {
                &:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0;
                    border-top-right-radius: 50px;
                    border-bottom-right-radius: 50px;
                    border-top: 0px solid #178EFC80;
                    border-left: 1px solid #178EFC80;
                    border-right: 3px solid #178EFC80;
                    border-bottom: 0px solid #178EFC80;
                    height: 100%;
                }
            }
        }
    }
    &.host-msg {
        .message-box {
            .username {
                .position {
                    color: #22B060;
                }
            }
            .message-text {
                &:after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0;
                    border-top-right-radius: 50px;
                    border-bottom-right-radius: 50px;
                    border-top: 0px solid #22B06080;
                    border-left: 1px solid #22B06080;
                    border-right: 3px solid #22B06080;
                    border-bottom: 0px solid #22B06080;
                    height: 100%;
                }
            }
        }
    }
    &.reply-msg {
        .replying-on-msg {
            background: #fff;
            border-radius: 8px;
            overflow: hidden;
            padding: 8px 10px;
            .username {
                max-width: 100%;
                width: 100%;
                margin: 0;
                text-align: left;
                .position {
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0 5px 0 3px;
                    &.co-host {
                        color: #178EFC;
                    }
                    &.host {
                        color: #22B060;
                    }
                }
            }
            .message-text {
                background: #fff;
                box-shadow: none;
                padding: 8px 0 0;
                border-radius: 0;
                text-align: left;
                p {
                    margin: 0;
                }
            }
        }
        .message-text {
            > p {
                text-align: left;
                display: inline-block;
                width: 100%;
                margin-top: 8px;
            }
        }
    }
    &.replied-msg {
        .message-box {
            .message-text {
                .replying-on-msg {
                    background: #fff;
                    border-radius: 8px;
                    overflow: hidden;
                    padding: 11px 8px;
                    margin-bottom: 8px;
                    .username {
                        width: 100%;
                        max-width: 100%;
                        .position {
                            &.co-host {
                                color: #178EFC;
                            }
                            &.host {
                                color: #22B060;
                            }
                        }
                    }
                    .message-text {
                        background: none;
                        border-radius: none;
                        box-shadow: none;
                        padding: 8px 0 0;
                        &:after {
                            border: none;
                        }
                    }
                }
            }
        }
    }
}

.msg-to-reply {
    position: relative;
    a.cancel-reply {
        position: absolute;
        right: 10px;
        top: 10px;
    } 
    .chat-message {
        padding: 10px;
        background: #fff;
        .message-box {
            background: #F2F2F4;
            border-radius: 8px;
            max-width: calc(100% - 30px);
            padding: 10px 20px;
            .username {
                width: 100%;
                max-width: 100%;
                margin-right: 0;
                span.position {
                    font-weight: 500;
                    font-size: 12px;
                    margin: 0 5px 0 3px;
                }
            }
            .message-text {
                max-width: 100%;
                background: none;
                box-shadow: none;
                border-radius: none;
                padding: 0;
                margin-top: 3px;
            }
        }
    }
}

// Empty message
.empty-area-msg {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    text-align: center;
    padding: 0 30px;
    h4 {
        margin: 10px 0 5px;
        font-size: 16px;
        color: #070029;
    }
    p {
        margin: 0;
        font-size: 15px;
        color: #999999;
        a {
            font-weight: 600;
            color: #178EFC;
            font-size: 16px;
            text-decoration: none;
        }
    }
    button {
        margin-top: 25px;
        width: 100%;
    }
}

// chat input
.chat-message-input {
    position: absolute;
    bottom: 0;
    padding: 10px;
    width: 100%;
    align-items: center;
    justify-content: stretch;
    background: #fff;
    gap: 10px;
    border-top: 1px solid #E8E8EA;
    input {
        flex: 1;
    }
    button {
        min-width: auto;
        padding: 8px;
        height: 40px;
    }
    .private-chat-user-list {
        position: absolute;
        bottom: 0;
        padding-top: 5px;
        margin-bottom: 65px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0px 4px 48px 0px #2B2B3014;
        max-width: 210px;
        input {
            margin: 5px 10px;
            width: calc(100% - 20px);
            font-size: 14px;
            position: sticky;
            z-index: 1;
        }
        ul {
            max-height: 210px;
            overflow-y: auto;
            li {
                border-bottom: 1px solid #E8EAF0;
                &:last-child {
                    border: none;
                }
                a {
                    font-size: 12px;
                    color: #2B2B30;
                    font-weight: 500;
                    padding: 10px 15px;
                    width: 100%;
                    display: block;
                    text-decoration: none;
                }
            }
        }
    }
    .ew-mention-input {
        width: 100%;
        border: 1px solid #E8E8EA;
        border-radius: 8px;
        padding: 0 10px;
        height: 48px;
        max-width: calc(100% - 55px);
        flex: 1;
        p {
            margin: 0;
            outline: none;
            border: none;
            height: 48px;
            line-height: 48px;
            white-space: nowrap;
            overflow-x: hidden;
            position: relative;

            span.mention-user {
                color: #178EFC;
            }
            // &:before {
            //     content: attr(data-placeholder); // this will take the placeholder attribute of the div element and use as content
            //     color: #aaa;
            // }
            // &:focus {
            //     &:before {
            //         display: none;
            //     }
            // }
        }
    }
}
.chat-input {
    border: 1px solid #ccc;
    padding: 10px;
    min-height: 40px;
    width: 100%;
    outline: none;
    white-space: pre-wrap;
    font-family: Arial, sans-serif;
    color: black;
    direction: ltr;
  }
  
  .mention {
    color: blue;
  }

.sticky-message-footer {
    position: absolute;
    bottom: 60px;
    width: 100%;
    .private-msg {
        background: #E8EDFB;
        height: 24px;
        width: 100%;
        position: relative;
        p {
            font-size: 12px;
            color: #666666;
        }
        a {
            position: absolute;
            right: 10px;
            cursor: pointer;
        }
    }
}

.sidebar-info-box {
    .sidebar-tabs-header {
        border-bottom: 1px solid #E8EDFB;
        padding: 20px;
        .sidebar-tabs {
            background: #E8E8EA;
            height: 44px;
            border-radius: 8px;
            padding: 6px;
            gap: 10px;
            width: fit-content;
            margin: 0 auto;
            button {
                padding: 0;
                border: none;
                height: 100%;
                flex: 1;
                background: none;
                border-radius: 4px;
                color: #666666;
                font-size: 14px;
                font-weight: 600;
                &.active {
                    background: #fff;
                    box-shadow: 0px 1px 2px 0px #1018280F;
                    box-shadow: 0px 1px 3px 0px #1018281A;
                    color: #178EFC;
                }
            }
        }
    }
}

.polls-content-area {
    .polls-inner-container {
        overflow: auto;
        height: calc(100vh - 207px);
        padding: 0;
        .empty-area-msg {
            margin-top: 0;
        }
    }
    .tabs-inner-heading {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        margin-bottom: 15px;
        margin-top: 0;
        padding: 20px 22px 0;
        display: flex;
        align-items: center;
        img {
            margin-right: 5px;
            cursor: pointer;
        }
    }
    .polls-info-main {
        border: 1px solid #E8EAF0;
        box-shadow: 0px 4px 48px 0px #2B2B300A;
        border-radius: 8px;
        padding: 20px;
        margin: 0 22px 10px;
        h3 {
            margin: 0 0 15px;
            font-size: 16px;
            color: #333333;
            position: relative;
            span {
                position: absolute;
                right: 0;
                top: 4px;
                font-size: 12px;
                font-weight: 400;
                color: #666666;
            }
        }
        ul {
            li {
                justify-content: space-between;
                position: relative;
                padding: 12px;
                border-radius: 8px;
                margin-bottom: 5px;
                p {
                    font-size: 14px;
                    color: #666666;
                    margin: 0;
                    max-width: calc(100% - 92px);
                    flex: 1;
                    line-height: 20px;
                    .polls-percntage {
                        background: #E8EDFB;
                        height: 5px;
                        border-radius: 30px;
                        margin-top: 8px;
                        position: relative;
                        overflow: hidden;
                        span {
                            background: #178EFC;
                            left: 0;
                            border-radius: 30px;
                            height: 5px;
                            transition: all .5s;
                            top: 0;
                            position: absolute;
                        }
                    }
                }
                > span {
                    color: #666666;
                    font-size: 14px;
                    position: absolute;
                    right: 0;
                    bottom: 7px;
                }
                &.low-vote {
                    p {
                        .polls-percntage {
                            span {
                                background: #A3A3A3;
                            }
                        }
                    }
                    span {
                        right: 8px;
                    }
                }
                &.high-vote {
                    background: #E8E8EA;
                    span {
                        right: 8px;
                    }
                }
            }
        }
        .votes-info {
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            align-items: center;
            margin-top: 30px;
            .total-views {
                background: #E8EDFB;
                padding: 4px 10px;
                border-radius: 4px;
                height: 32px;
                color: #666666;
                font-weight: 500;
                margin-right: 5px;
            }
        }
        button {
            width: 100%;
            color: #fff;
            margin-top: 20px;
        }
        &.polls-preview {
            ul {
                li {
                    justify-content: flex-start;
                    label {
                        margin-right: 15px;
                        input {
                            width: 20px;
                            height: 20px;
                            min-height: auto;
                            padding: 0;
                            margin: 0;
                        }
                    }
                    p {
                        max-width: 100%;
                        flex: 1;
                    }
                }
            }
            .disabled-button {
                color: #666666;
            }
        }
        &.attendee-polls-preview {
            ul {
                li {
                    justify-content: flex-start;
                    padding: 12px 0;
                    label {
                        margin-right: 15px;
                        input {
                            width: 20px;
                            height: 20px;
                            min-height: auto;
                            padding: 0;
                            margin: 0;
                        }
                    }
                    p {
                        max-width: 100%;
                        span {
                            display: flex;
                            justify-content: space-between;
                            padding-right: 20px;
                            position: relative;
                            right: 0;
                            span.live-votes {
                                position: absolute;
                                right: 0;
                                bottom: 0;
                                padding-right: 0;
                            }
                        }
                    }
                    &.high-vote {
                        background: none;
                    }
                }
            }
        }
    }
}

.saved-polls-outer {
    .saved-polls-listing {
        .saved-poll {
            padding: 16px 22px;
            gap: 8px;
            border-bottom: 1px solid #E8EAF0;
            .polls-question-box {
                flex: 1;
                p {
                    margin: 0;
                    margin-bottom: 1em;
                    justify-content: space-between;
                    align-items: center;
                }
                .polls-action {
                    gap: 10px;
                }
            }
        }
    }
}

// people
.People-area {
    height: 100%;
    overflow-y: auto;
}
.search-bar {
    width: 100%;
    padding: 20px;
    position: sticky;
    z-index: 1;
    top: 0;
    background: #fff;
    input {
        width: 100%;
        position: relative;
        height: 40px;
        min-height: 40px;
        padding: 0 20px 0 40px;
    }
    span.search-icon {
        position: absolute;
        left: 21px;
        top: 21px;
        width: 40px;
        height: 40px;
    }
}
.invite-button-box {
    padding: 0 20px;
    button {
        width: 100%;
    }
}

.participants-listing {
    .accordion {
        border: none;
        padding: 6px;
        .accordion__item + .accordion__item {
            border-top: none;
        }
    }
    .accordion__button {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        display: flex;
        align-items: center;
        background: none;
        padding: 20px 18px 0;
        position: relative;
        img {
            margin-right: 10px;
        }
        &[aria-expanded="false"] {
            &:before {
                transform: rotate(-180deg);
            }
        }
        &:hover {
            background: none;
        }
    }

     .accordion__button:before {
         display: inline-block;
         content: '';
         background: url('../../images/accordion-icon.png') no-repeat;
         height: 24px;
         width: 24px;
         margin-right: 12px;
         transform: rotate(-45deg);
         border: none;
         position: absolute;
         right: 0;
     }
     .accordion__button[aria-expanded='true']::before, .accordion__button[aria-selected='true']::before {
         transform: rotate(0deg);
     }
}
.participants-list {
    ul {
        li {
            padding: 8px 0;
            .user-info {
                align-items: center;
                .alphabet-name {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    background: #FFD3BF;
                    margin-right: 16px;
                    font-size: 14px;
                    font-weight: 400;
                }
                .username {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    span.host {
                        color: #22B060;
                    }
                    span.co-host {
                        color: #178EFC;
                    } 
                }
                .user-actions {
                    a {
                        width: 24px;
                        height: 24px;
                    }
                }
                .joined-participants-info {
                    justify-content: space-between;
                    flex: 1;
                    .user-actions {
                        a.mic-on, a.cam-on {
                            svg.on {
                                display: block;
                            }
                            svg.off {
                                display: none;
                            }
                            &:hover {
                                svg.on {
                                    path {
                                        fill: #333;
                                    }
                                }
                            }
                        }
                        a.mic-off, a.cam-off {
                            svg.on {
                                display: none;
                            }
                            svg.off {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Countdown timer
.countdown-timer {
    position: relative;
    width: 100%;
    height: 100%;
    .timer-inner {
        flex-direction: column;
        > div {
            svg {
                box-shadow: 0 12px 20px 0px #4E4E4E3D;
                border-radius: 50%;
                > path {
                    stroke-width: 1px !important;
                    // stroke: #f5f5f5 !important;
                }
                Path + path {
                    // stroke: #FFFFFF !important;
                    stroke-width: 3px !important;
                }
            }
            > div {
                font-size: 60px;
                color: #ffffff;
            }
        }
        span {
            color: #ffffff;
            font-size: 20px;
            font-weight: 500;
            margin: 20px 0;
        }
        button {
            color: #fff;
        }
    }
}

// Offers
.offers-area {
    .sidebar-tabs-header {
        .sidebar-tabs {
            &.offers-tabs-header {
                width: 70% !important;
                margin: 0 auto;
            }
        }
    }
}
.offers-inner-container {
    overflow: auto;
    height: calc(100vh - 207px);
    padding: 0 22px 10px;
}
.saved-offers-listing {
    .saved-offers {
        margin-bottom: 40px;
        .offer-icon {
            margin-right: 10px;
        }
        .offers-question-box {
            flex: 1;
            flex-direction: column;
            p {
                margin: 0;
                font-size: 14px;
                font-weight: 500;
                color: #12262B;
                justify-content: space-between;
                &.offers-text {
                    font-weight: 400;
                    color: #666666;
                    margin-bottom: 10px !important;
                }
            }
            .offers-action {
                gap: 10px;
            }
        }
    }
}

.offers-info-main {
    border: 1px solid #E8EAF0;
    background: #fff;
    box-shadow: 0px 4px 48px 0px #2B2B3014;
    border-radius: 8px;
    padding: 25px 20px;
    .offer-preview {
        text-align: center;
        h3 {
            margin: 0;
            font-size: 20px;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 15px;
            color: #333333;
            line-height: 32px;
        }
        p {
            margin-bottom: 25px;
            color: #333333;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            margin-top: 0;
            gap: 20px;
        }
        button.widget-action-registration {
            color: #fff;
            background: rgb(69, 6, 242);
            font-weight: 500;
            min-height: 40px;
            width: 100%;
            box-shadow: 0 0 0 0 rgba(0,0,0,.2),0 0 0 0 rgba(0,0,0,.14),0 0 0 0 rgba(0,0,0,.12);
            span.material-icons {
                margin-right: 8px;
            }
            line-height: normal !important;
            &.small-btn-offer {
                height: 44px !important;
                font-size: 14px !important;
                padding: 0 12px !important;
            }
            &.medium-btn-offer {
                height: 52px !important;
                font-size: 15px !important;
                padding: 0 25px !important;
            }
            &.large-btn-offer {
                height: 60px !important;
                font-size: 19px !important;
                padding: 0 25px !important;
            }
        }
        .countdown-timer-offer {
            p {
                span {
                    flex-direction: column;
                    font-size: 34px;
                    font-weight: 500;
                    position: relative;
                    span {
                        font-size: 13px;
                        color: #666666;
                        font-weight: 300;
                        &::after {
                            content: "";
                        }
                    }
                    &::after {
                        content: ":";
                        position: absolute;
                        right: -14px;
                        color: #000;
                    }
                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
        .offers-numbers {
            margin-top: 20px;
            span.offer-views {
                height: 32px;
                background: #E8EDFB;
                padding: 4px 10px;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 500;
                color: #666666;
                margin-right: 10px;
            }
            span.offer-clicks {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

.ew-easycast {
    position: absolute;
    width: 336px;
    top: 70px;
    border-radius: 8px;
    background: #fefefe;
    padding: 18px;
    right: 0;
    z-index: 9;
    max-height: 80vh;
    overflow-y: auto;
    transition: opacity 5s ease-in-out;
    box-shadow: 0px 4px 48px 0px rgba(43, 43, 48, 0.0784313725);
    display: none;
    &.open-dropdown {
        display: block;
    }
    ul {
        margin: 0 !important;
        flex-wrap: wrap;
        gap: 15px;
        li {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #232323;
            font-size: 14px;
            min-width: 90px;
            a {
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #333;
                text-decoration: none;
                img {
                    width: 48px;
                    height: 48px;
                    margin-bottom: 7px;
                }
            }
        }
    }
}

/*----------------------*/
.base-timer {
    position: relative;
    width: 250px;
    height: 250px;
  margin: auto;
}

.base-timer__svg {
    transform: scaleX(-1);
}

.base-timer__circle {
    fill: none;
    stroke: none;
}

.base-timer__path-elapsed {
    stroke-width: 6px;
    stroke: #efefef;
}

.base-timer__path-remaining {
    stroke-width: 4px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
}

.base-timer__path-remaining.green {
    color: #39b37d;
}

.base-timer__path-remaining.orange {
    color: orange;
}

.base-timer__path-remaining.red {
    color: red;
}

.base-timer__label {
    position: absolute;
    width: 250px;
    height: 250px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0.3px;
}

span.mentioned-user {
    color: blue;
}

.highlight {
    color: blue;
  }
  .cursor {
    display: inline-block;
    width: 1px;
    background-color: black;
    animation: blink 1s step-end infinite;
    height: 15px;
  }
  
  @keyframes blink {
    from, to {
      background-color: transparent;
    }
    50% {
      background-color: black;
    }
  }

.chat-message-input > div > input {
    position: absolute;
    /* left: -9999px; */
    background: none;
    top: 0px;
    left: 0px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    color: transparent;
    border: none;
    padding: 0 7px;
    height: 31px;
    width: 100%;
    min-height: 31px;
    z-index: -2;
}

// new message notification
.new-message-notify {
    position: absolute;
    bottom: 75px;
    right: 10px;
    background: #178EFC;
    color: #fff;
    border-radius: 4px;
    padding: 7px 10px;
    font-size: 14px;
    z-index: 2;
    cursor: pointer;
    img {
        margin-right: 4px
    }
}

.refresh-screen {
    position: absolute;
    right: 10px;
    bottom: 50px;
    cursor: pointer;
    z-index: 91;
    img {
        transition: all .5s;
    }
    &:hover {
        img {
            transform: rotate(150deg);
        }
    }
}

.presenter-options-dropdown {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 91;
    span {
        background: rgba(0, 0, 0, .3);
        border-radius: 50%;
        width: 36px;
        height: 36px;
        cursor: pointer;
    }
    ul {
        position: absolute;
        bottom: 40px;
        background: #fff;
        border-radius: 4px;
        width: 160px;
        right: 0;
        overflow: hidden;
        li {
            padding: 10px;
            border-bottom: 1px solid #E8EAF0;
            cursor: pointer;
            img {
                margin-right: 8px;
            }
            span {
                background: none;
                width: auto;
                height: auto;
                color: #666666;
            }
            &:hover {
                background: #E8EAF0;
                span {
                    color: #333333;
                }
            }
        }
    }
}

.sidebar-header {
    align-items: center;
    display: flex;
    padding: 14px 15px;
    border-bottom: 1px solid #E8E8EA;
    color: #333333;
    font-weight: 500;
    font-size: 18px;
    a {
        position: absolute;
        right: 5px;
        top: 14px;
        height: 24px;
        img {
            margin-right: 10px;
        }
    }
}

.click-for-sound {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    &:before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        z-index: 0;
        border-radius: 4px;
    }
    span {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all .5s;
        cursor: pointer;
        background: #00000059;
        border: 1px solid #E8E8EA99;
        padding: 10px 18px;
        border-radius: 8px;
        font-weight: 500;
        font-size: 18px;
        z-index: 1;
        img {
            margin-right: 10px;
            &.off {
                display: flex;
            }
            &.on {
                display: none;
            }
        }
        &:hover {
            img {
                &.off {
                    display: none;
                }
                &.on {
                    display: flex;
                }
            }
        }
    }
}