// Button 

button {
    height: 48px;
    border: 1px solid #666666;
    min-width: 100px;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
    color: #666;
    font-family: "Roboto", sans-serif;
    background: #fff;
    cursor: pointer;
    &.primary-button {
        background: #178EFC;
        border: 1px solid #178EFC;
        color: #fff;
        &:hover {
            background: #1272CA;
        }
    }
    &.secondary-button {
        background: #DFEBFF;
    }
    &.outlined-button {
        background: none;
        border: 1px solid #666666;
        color: #666666;
    }
    &.warning-button {
        background: #E44258;
        border: none;
        color: #fff;
        &:hover {
            background: #C42339;
        }
    }
    &.btn-sm {
        height: 32px;
        border-radius: 4px;
        padding: 2px 15px;
        font-size: 14px;
    }
    &.btn-md {
        height: 40px;
        padding: 5px 24px;
    }
    &.text-button {
        border: none;
        padding: 0;
        height: auto;
        color: #178EFC;
    }
    &.disabled-button {
        background: #E8E8EA;
        color: #666666 !important;
        pointer-events: none;
        border: none;
    }
}

a {
    cursor: pointer;
}

// Input
input {
    border: 1px solid #E8E8EA;
    border-radius: 8px;
    color: #666666;
    font-size: 16px;
    font-weight: 400;
    min-height: 55px;
    padding: 0 20px;
    &.input-md {
        min-height: 48px;
    }
    &.input-sm {
        min-height: 40px;
    }
    &:hover {
        border-color: hsl(0, 0%, 70%);
    }
    &:focus {
        border: 1px solid #178EFC;
        outline: 1px solid #178EFC;
    }
    &.disabled {
        background: #E8E8EA;
        pointer-events: none;
    }
}

// Select

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

// Textarea
textarea {
    border: 1px solid #E8E8EA;
    border-radius: 8px;
    color: #666666;
    font-size: 16px;
    font-weight: 400;
    min-height: 55px;
    padding: 10px 20px;
    margin-top: 10px;
    max-width: 100%;
    &:focus {
        border: 1px solid #178EFC;
        outline: 1px solid #178EFC;
    }
}

// Dropdown
.dropdown-area {
    position: relative;
    display: inline-block;
    height: 24px;
}

.dropdown-button {
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    padding: 0;
    height: 24px;
    width: 24px;
    min-width: 24px;
}

.dropdown-listing {
    position: absolute;
    top: 100%;
    background-color: #ffffff;
    min-width: 175px;
    box-shadow: 0px 4px 48px 0px #2B2B3014;
    z-index: 1;
    right: 0;
    left: inherit;
    border-radius: 16px;
    padding: 10px 0;
    .dropdown-item {
        padding: 12px 16px;
        cursor: pointer;
        // position: relative;
        font-size: 14px;
        color: #666666;
        border-bottom: 1px solid #E8EAF0;
        height: 42px;
        display: flex;
        align-items: center;
        img {
            margin-right: 10px;
        }
        &:hover {
            background-color: #DFEBFF;
        }
        .open-arrow {
            position: absolute;
            right: 0;
            margin-top: 3px;
        }
    }
}
.submenu {
    position: absolute;
    top: 0;
    right: 100%;
    background-color: #ffffff;
    min-width: 175px;
    box-shadow: 0px 4px 48px 0px #2B2B3014;
    z-index: 9;
    border-radius: 16px;
    padding: 10px 0;
    height: 100%;
    .submenu-item {
        padding: 12px 16px;
        height: 42px;
        cursor: pointer;
        font-size: 14px;
        color: #666666;
        border-bottom: 1px solid #E8EAF0;
        &:hover {
            background-color: #DFEBFF;
        }
    }
}

// Loader
.loader-bg {
    border-radius: 4px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: 0 auto;
    z-index: 91;
    align-items: center;
    width: 100%;
    height: 100%;
    .blur-bg {
        background-color: rgba(68, 68, 68, 0.4117647059);
        filter: blur(5px);
        -webkit-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(3px);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.section-loader {
    position: relative;
    flex-direction: column;
    z-index: 1;
    span {
        color: #fff;
    }
}
  
.circle-loader {
    position: relative;
    left: 12px;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #178EFC;
    border-top: 2px solid #94cbff;
    -webkit-animation: animate 1s infinite linear;
    animation: animate 1s infinite linear;
    -ms-animation: animate 1s infinite linear;
}
  
@keyframes animate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
}