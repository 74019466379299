.mobile {

    .hide-on-mobile {
        display: none !important;
    }

    .hide-on-desktop {
        display: flex !important;
    }

    .layout-header {
        .ew-event-details {
            padding-left: 0;
        }
    }

    // Sidebar
    &.ew-container-layout {
        .left-layout {
            width: 100%;
            .layout-header {
                width: 100%;
                .event-option-menu {
                    display: none;
                }
                .ew-event-actions {
                    .exit-actions-btn {
                        display: none;
                        @media screen and (orientation: landscape) {
                            display: flex;
                        }
                    }
                    button {
                        padding: 10px 15px;
                    }
                    .dropdown.open-dropdown {
                        position: fixed;
                        bottom: 74px;
                        right: 10px;
                        height: fit-content;
                        top: inherit;
                        @media screen and (orientation: landscape) {
                            top: 55px;
                            bottom: inherit;
                            right: 0;
                            position: absolute;
                        }
                    }
                }
                .ew-event-details {
                    .not-live-label {
                        border: none;
                        margin-top: 7px;
                        padding: 2px 5px 2px 0;
                    }
                }
                @media screen and (orientation: landscape) {
                    width: calc(100% - 80px);
                }
            }
            @media screen and (orientation: landscape) {
                width: calc(100% - 80px);
            }
        }
        .right-layout {
            width: 100%;
            top: auto;
            z-index: 91;
            .sidebar {
                padding: 8px 0;
                display: flex;
                justify-content: center;
                ul {
                    flex-direction: row;
                    position: relative;
                    width: 100%;
                    justify-content: space-between;
                    padding: 0 12px;
                    li {
                        margin-bottom: 0;
                        &.leave-event {
                            background: #E44258;
                            a {
                                color: #fff;
                                img {
                                    filter: brightness(0) invert(1);
                                }
                                span {
                                    color: #fff;
                                }
                            }
                        }
                        a {
                            .ew-action-icon {
                                min-height: 27px;
                            }
                        }
                    }
                }
            }
            .sidebar-info-box {
                right: 0;
                top: 0;
                width: 100%;
                height: calc(100% - 71px);
                @media screen and (orientation: landscape) {
                    height: 100%;
                    right: 80px;
                    width: calc(100% - 80px);
                }
                .sidebar-header {
                    align-items: center;
                    display: flex;
                    padding: 14px 15px;
                    border-bottom: 1px solid #E8E8EA;
                    color: #333333;
                    font-weight: 500;
                    font-size: 18px;
                    a {
                        height: 24px;
                        img {
                            margin-right: 10px;
                        }
                    }
                }
            }
            @media screen and (orientation: landscape) {
                top: 0;
                width: 80px;
                .sidebar {
                    flex-direction: column;
                    padding-top: 0;
                    ul {
                        flex-direction: column;
                        gap: 10px;
                        &.leave-event {
                            margin-left: 0;
                            margin-top: 10px;
                        }
                    }
                }
            }
            .leave-event-mobile {
                background: #E44258;
                @media screen and (orientation: landscape) {
                    display: none !important;
                }
                a {
                    color: #fff;
                    img {
                        filter: brightness(0) invert(1);
                    }
                    span {
                        color: #fff;
                    }
                }
            }
        }
        .polls-content-area .polls-inner-container {
            height: calc(100vh - 215px);
            .empty-area-msg {
                margin-top: 0;
            }
        }
        @media screen and (orientation: landscape) {
            .polls-content-area .polls-inner-container {
                height: calc(100vh - 155px);
            }
        }
    }

    .ew-video-container {
        height: calc(100vh - 155px);
        @media screen and (orientation: landscape) {
            height: calc(100vh - 72px);
        }
        .presenters {
            .ew-n-presenters {
                width: 100%;
                .presenter-column {
                    .ew-video-wrapper {
                        .presenter-name {
                            padding: 5px 15px;
                            height: 30px;
                            font-size: 12px;
                            left: 5px;
                            bottom: 5px;
                        }
                        .ew-presenter-profile {
                            .img-wrapper {
                                font-size: 1.4em;
                                max-width: 60px;
                                max-height: 60px;
                                width: 40%;
                                height: 40%;
                            }
                            .ew-presenter-title {
                                font-size: 1em;
                            }
                        }
                    }
                }
            }
            &.active-presenter-1 {
                .presenter-column {
                    width: 100%;
                    height: 100%;
                }
            }
            &.active-presenter-2 {
                .presenter-column {
                    width: 100%;
                    height: calc(50% - 8px);
                    @media screen and (orientation: landscape) {
                        width: calc(50% - 8px);
                        height: 100%;
                    }
                }
            }
            &.active-presenter-3 {
                .presenter-column {
                    width: 100%;
                    height: calc(33.3% - 8px);
                    @media screen and (orientation: landscape) {
                        width: calc(50% - 8px);
                        height: 50%;
                    }
                }
            }
            &.active-presenter-4 {
                .presenter-column {
                    width: calc(50% - 8px);
                    height: 50%;
                }
            }
            &.active-presenter-5, &.active-presenter-6 {
                .presenter-column {
                    width: calc(50% - 8px);
                    height: 32.3%;
                    @media screen and (orientation: landscape) {
                        width: calc(33.3% - 8px);
                        height: 50%;
                    }
                }
            }
            &.active-presenter-7, &.active-presenter-8 {
                .presenter-column {
                    width: 25%;
                    height: 50%;
                }
            }
            &.active-presenter-9, &.active-presenter-10, &.active-presenter-11, &.active-presenter-12 {
                .presenter-column {
                    width: 25%;
                    height: 33.33%;
                }
            }
            &.screen_shared_fullscreen {
                align-items: flex-end;
                .ew-n-presenters {
                    flex-wrap: wrap;
                    height: 170px;
                    width: 100%;
                    justify-content: center;
                    @media screen and (orientation: landscape) {
                        height: 75px;
                        flex-wrap: nowrap;
                    }
                    .presenter-column {
                        min-width: auto;
                        &.screen-sharing-user-fullscreen {
                            height: calc(100% - 355px);
                            width: calc(100% - 18px);
                            @media screen and (orientation: landscape) {
                                height: calc(100% - 175px);
                                width: calc(100% - 100px);
                            }
                        }
                    }
                }
                &.active-presenter-2 {
                    .presenter-column {
                        width: 49%;
                        height: 100%;
                    }
                }
                &.active-presenter-3 {
                    .presenter-column {
                        width: 32.3%;
                        height: 100%;
                    }
                }
                &.active-presenter-4 {
                    .presenter-column {
                        width: calc(50% - 3px);
                        height: 50%;
                    }
                }
                &.active-presenter-5, &.active-presenter-6 {
                    .presenter-column {
                        width: 32.3%;
                        height: 50%;
                    }
                }
                @media screen and (orientation: landscape) {
                    &.active-presenter-1, &.active-presenter-2, &.active-presenter-3, &.active-presenter-4, &.active-presenter-5, &.active-presenter-6 {
                        .presenter-column {
                            height: 100%;
                            min-width: 105px;
                            max-width: 105px;
                            &.screen-sharing-user-fullscreen {
                                max-width: calc(100% - 100px);
                                min-width: calc(100% - 100px);
                            }
                        }
                    }
                }
            }
        }
    }

    &.attendee-layout {
        .right-layout {
            .sidebar {
                ul {
                    justify-content: center;
                    gap: 20px;
                }
            }
        }
    }
    .ew-mobile-options-dropdown {
        position: absolute;
        top: 70px;
        border-radius: 8px;
        background: #fefefe;
        padding: 18px;
        right: 0;
        z-index: 9;
        max-height: 80vh;
        overflow-y: auto;
        transition: .3s ease-in-out;
        box-shadow: 0px 4px 48px 0px rgba(43, 43, 48, 0.0784313725);
        width: calc(100% - 20px);
        margin: 0 10px;
        // display: none;
        display: block;
        &.open-dropdown {
            top: -206px;
        }
        ul {
            gap: 4px;
            flex-wrap: wrap;
            li {
                width: 80px;
                height: 80px;
                border: 1px solid #E8EAF0;
                box-shadow: 0px 4px 48px 0px #2B2B3014;
                border-radius: 8px;
                a {
                    height: 100%;
                    flex-direction: column;
                    text-decoration: none;
                    color: #666666;
                    text-align: center;
                    font-size: 14px;
                    span {
                        min-height: 29px;
                        img {
                            &.default {
                                display: block;
                            }
                            &.active {
                                display: none;
                            }
                        }
                    }
                }
                &:hover, &.active {
                    background: #DFEBFF;
                    a {
                        color: #178EFC;
                        span {
                            img {
                                &.default {
                                    display: none;
                                }
                                &.active {
                                    display: block;
                                }
                            }
                            &.icon-svg {
                                svg {
                                    path {
                                        fill: #178EFC;
                                    }
                                }
                            }
                        }
                    }
                }
                &.disabled {
                    opacity: .7;
                    pointer-events: none;
                }
            }
        }
        @media screen and (orientation: landscape) {
            right: -214px;
            width: 205px;
            &.open-dropdown {
                top: 70px;
                right: 74px;
            }
        }
    }

    // Modals
    .modal {
        .modal-container {
            max-width: calc(100% - 20px);
        }
        .live-room-settings-box {
            flex-direction: column;
        }
        &.session-settings {
            .modal-content {
                flex-direction: column;
                .settings-left-sidebar {
                    width: 100%;
                    padding: 30px 15px;
                    h3, h4 {
                        display: none;
                    }
                    .tabs {
                        flex-direction: row;
                        button {
                            flex-direction: column;
                            padding: 6px 10px;
                            min-width: fit-content;
                            align-items: center;
                        }
                    }
                }
                .settings-right-area {
                    padding-top: 20px;
                    .ew-setting-area-section {
                        padding: 0 15px 30px;
                        .select-video-layout {
                            flex-wrap: wrap;
                        }
                    }
                    .camera-check-box {
                        width: 100%;
                    }
                }
            }
        }
    }
    .ew-easycast {
        right: inherit;
        margin: 0;
        top: inherit;
        bottom: 277px;
        width: calc(100% - 20px);
        left: 10px;
        z-index: 92;
        @media screen and (orientation: landscape) {
            right: 291px;
            width: 232px;
            top: 70px;
            left: inherit;
            bottom: inherit;
        }
    }
}

.refresh-screen {
    right: 20px;
    bottom: 58px;
}

