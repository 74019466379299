
.ew-container-layout {
    &.recording-layout {
        .left-layout {
            width: 100%;
            .ew-video-container {
                margin-top: 0;
                height: 100vh;
                .presenters {
                    &.screen_shared_fullscreen {
                        .presenter-column {
                            &.screen-sharing-user-fullscreen {
                                width: calc(100% - 20px);
                                height: calc(100% - 128px);
                                left: 10px;
                                padding: 0 !important;
                                z-index: 1;
                                position: fixed;
                                top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    
    &.with-header {
        .left-layout {
            .layout-header {
                display: flex !important;
            }
            .ew-video-container {
                .presenters.screen_shared_fullscreen {
                    .presenter-column {
                        &.screen-sharing-user-fullscreen {
                            top: 80px;
                            height: calc(100% - 200px);
                        }
                    }
                }
            }
        }
    }
}